#wrapper {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;
  
  width: 100vw;
  height: 100vh;

  background-color: #ffcccb ;
  color: #333333;
}

#main {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding-top: 16px;
}

#page-title {
  font-size: min(32px, 8vw);
  font-weight: 500;
}

#period-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-size: min(32px, 8vw);

}

#period-wrapper > span {
  padding-left: min(12px, 1.5vw);
}

#footer {
  margin-left: auto;
  padding-right: 16px;
  padding-bottom: 8px;
  font-weight: 400;
  color: black;
}

@media only screen and (max-width: 600px) {
  #wrapper {
    align-items: flex-start;
    
    min-height: 100vh;
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available;
  }
  
  #main {
    padding-left: min(8px, 2vw);
  }
}